import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss'],
  standalone: true,
  imports: [MatIcon, MatDialogClose],
})
export class PrivacyPolicyDialogComponent {
  url: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url?: string },
    private sanitizer: DomSanitizer
  ) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.url || '');
  }
}
